import React from "react"

const OutlinkText = ({ href, children, style, isStrong = true }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={`rabelais-dark-link`}
      style={style}
      href={href}
    >
      {isStrong === true ? <strong>{children}</strong> : <>{children}</>}
    </a>
  )
}

export default OutlinkText
