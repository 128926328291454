import React from "react"

import Layout from "../layout"
import SEO from "../seo"
import PosterImage from "../shinel-poster"

const BaseTemplate = ({
  title,
  description,
  ogImagePath,
  children,
  hideHeader = false,
  removePadding = false,
}) => (
  <Layout hideHeader={hideHeader} removePadding={removePadding}>
    <SEO
      title={title}
      titleTemplate={`%s`}
      description={description}
      image={ogImagePath}
    />
    <>{children}</>
  </Layout>
)

export default BaseTemplate
